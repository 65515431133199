<template>
  <div>
    <div v-if="business">
      <v-snackbar top :timeout="3000" v-model="snackbar">
        {{ snackbarText }}
      </v-snackbar>

      <v-row class="pa-0 ma-0 ml-7 mt-5">
        <h1>
          Información
          <span class="font-weight-light">General</span>
        </h1>
      </v-row>

      <div class="main-container">
        <v-container fluid class="screen-width fill-height">
          <v-row class="pa-0 ma-0">
            <v-col cols="12">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-img
                    v-on="on"
                    :src="business.cover ? business.cover.original : ''"
                    aspect-ratio="1"
                    @click="selectImage('banner')"
                    class="grey lighten-2 cover-up"
                    max-width="100%"
                    max-height="250"
                  >
                    <v-row
                      class="fill-height ma-0"
                      align="end"
                      justify="center"
                    >
                      <v-chip style="width: 20%" class="mb-5 img-tag">
                        Cover
                      </v-chip>
                    </v-row>
                  </v-img>
                </template>
                <span>Cambiar imágen de cover</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-img
                    v-on="on"
                    :src="business.logo ? business.logo.original : ''"
                    aspect-ratio="1"
                    @click="selectImage('brand')"
                    class="grey lighten-2 logo-up"
                    max-width="150"
                    max-height="150"
                  >
                    <v-row
                      class="fill-height ma-0"
                      align="end"
                      justify="center"
                    >
                      <v-chip class="mb-1 img-tag"> Logo </v-chip>
                    </v-row>
                  </v-img>
                </template>
                <span>Cambiar imágen de logo</span>
              </v-tooltip>

              <v-row class="pa-0 ma-0 pt-2 mt-2">
                <v-col cols="12" md="6">
                  <h2>Nombre corto</h2>
                  <v-text-field
                    type="text"
                    rounded
                    :disabled="user.type != 'sudo'"
                    :loading="loading"
                    outlined
                    placeholder="Ingrese el nombre corto"
                    v-model="business.shortName"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <h2>Nombre largo</h2>
                  <v-text-field
                    rounded
                    outlined
                    type="text"
                    :disabled="user.type != 'sudo'"
                    placeholder="Ingrese el nombre largo"
                    v-model="business.longName"
                    :loading="loading"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <h2>RTN</h2>
                  <v-text-field
                    rounded
                    outlined
                    type="text"
                    placeholder="Ingrese el RTN"
                    v-model="business.tinNumber"
                    :loading="loading"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <h2>Contacto</h2>
                  <v-text-field
                    rounded
                    outlined
                    :loading="loading"
                    type="text"
                    placeholder="Ingrese el nombre del contacto"
                    v-model="business.contactName"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <h2>Teléfono</h2>
                  <v-text-field
                    rounded
                    outlined
                    :loading="loading"
                    placeholder="Número de persona de contacto"
                    v-model="business.phone"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <h2>Correo electrónico</h2>
                  <v-text-field
                    rounded
                    :loading="loading"
                    outlined
                    placeholder="Correo electrónico"
                    v-model="business.email"
                  />
                </v-col>

                <v-col v-if="roles.includes(user.type)" cols="12" md="6">
                  <h2>Método de pago</h2>
                  <v-select
                    outlined
                    rounded
                    :loading="loading"
                    v-model="business.paymentType"
                    class="mt-5"
                    item-text="displayName"
                    item-value=".key"
                    :items="sortedPayments"
                  />
                </v-col>

                <v-col v-if="roles.includes(user.type)" cols="12" md="6">
                  <h2>Ciudad</h2>
                  <v-select
                    outlined
                    :loading="loading"
                    rounded
                    v-model="business.city"
                    class="mt-5"
                    item-text="name"
                    item-value=".key"
                    :items="cities"
                  />
                </v-col>
              </v-row>

              <v-row class="pa-0 ma-0">
                <v-col cols="12" md="6" sm="6">
                  <v-switch
                    :loading="loading"
                    v-model="business.active"
                    label="Activo"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <!-- <v-divider></v-divider> -->
                  <v-row justify="end">
                    <v-btn
                      class="save-btn"
                      :loading="loading"
                      @click="save"
                      color="primary"
                      dark
                    >
                      Guardar
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <input
      class="upload"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />

    <v-dialog v-model="uploadImageDialog" v-if="imgSrc" max-width="610px">
      <v-card>
        <v-card-title class="grid-close">
          <span class="headline">
            Vista previa imágen de
            {{ imageType === "banner" ? "portada" : "logo" }}
          </span>

          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="uploadImageDialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col v-if="imgSrc" cols="12">
                <v-row justify="center">
                  <img class="preview-image" :src="imgSrc" />
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="end" class="upload-btn" align="center">
              <v-btn
                @click.prevent="saveImage"
                class="mt-4 save-btn"
                color="primary"
                :loading="uploading"
                dark
              >
                Guardar
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState, mapActions } from "vuex";

export default {
  name: "corporative-general",

  data() {
    return {
      business: {
        kmConfiguration: {},
      },
      snackbar: false,
      uploading: false,
      cities: [],
      snackbarText: null,
      uploadImageDialog: false,
      paymentGateways: [],
      loading: true,
      imageType: null,
      imgSrc: "",
      imageFile: null,
      flatRates: [
        { text: "Tarifa fija", value: true },
        { text: "Calcular tarifa por distancia", value: false },
      ],
      roles: ["admin", "sudo", "AC", "EFCM"],
    };
  },
  async mounted() {
    this.$store.state.visibleSearch = false;

    this.getBusinessData();
  },

  computed: {
    ...mapState(["selectedCorporative", "user"]),

    sortedPayments: function () {
      function compare(a, b) {
        if (a.displayName < b.displayName) return -1;
        if (a.displayName > b.displayName) return 1;
        return 0;
      }

      return this.paymentGateways.sort(compare);
    },
  },
  methods: {
    ...mapActions(["addCorporative", "addBusiness"]),
    maxLength(evt, field, length) {
      if (field.length >= length) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    save() {
      if (
        this.business.shortName &&
        this.business.longName &&
        this.business.city
      ) {
        this.loading = true;
        let payload = {
          shortName: this.business.shortName,
          longName: this.business.longName,
          tinNumber: this.business.tinNumber ? this.business.tinNumber : "",
          contactName: this.business.contactName
            ? this.business.contactName
            : "",
          phone: this.business.phone ? this.business.phone : "",
          email: this.business.email ? this.business.email : "",

          active: this.business.active ? true : false,
          city: this.business.city,
          paymentType: this.business.paymentType
            ? this.business.paymentType
            : "",
        };

        db.collection("corporateCoupons")
          .doc(this.business[".key"])
          .update(payload)
          .then(() => {
            this.snackbarText = "Información actualizada exitosamente.";
            this.snackbar = true;
            this.loading = false;
          })
          .catch(() => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente";
            this.snackbar = true;
            this.loading = false;
          });
      } else {
        this.snackbarText = "Debe ingresar el nombre del comercio cargo.";
        this.snackbar = true;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    setImage(e) {
      this.imageFile = e.target.files[0];

      if (this.imageFile.type.indexOf("image/") === -1) {
        alert("Por favor seleccionar una imagen");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.uploadImageDialog = true;
        };

        reader.readAsDataURL(this.imageFile);
      } else {
        alert("Lo sentimos, formato no soportado");
      }
    },
    successUpload() {
      this.uploadImageDialog = false;
      this.snackbar = true;
      this.snackbarText = "Imágen actualizada";
    },

    selectImage(imageType) {
      this.imageType = imageType;
      this.$refs.input.click();
    },
    saveImage() {
      this.uploading = true;

      let bucket =
        this.imageType == "brand" || this.imageType == "banner"
          ? `gs://${process.env.VUE_APP_PROJECT_ID}-freight`
          : null;

      if (bucket) {
        fb.app()
          .storage(bucket)
          .ref()
          .child(
            `corporateCoupons/${this.selectedCorporative[".key"]}/${this.imageType}`
          )
          .put(this.imageFile)
          .then(() => {
            this.uploading = false;
            this.successUpload();
          })
          .catch((err) => {
            this.uploading = false;
            this.snackbar = true;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente";
          });
      }
    },

    async getBusinessData() {
      if (!this.selectedCorporative) {
        this.$router.push({ path: "/" });
      }

      console.log(this.selectedCorporative);

      await this.$binding(
        "business",
        db.collection("corporateCoupons").doc(this.selectedCorporative[".key"])
      );

      if (this.roles.includes(this.user.type)) {
        await this.$binding(
          "paymentGateways",
          db
            .collection("paymentGateways")
            .where("countryId", "==", this.business.country)
            .where("deleted", "==", false)
        );
        await this.$binding(
          "cities",
          db
            .collection("cities")
            .where("active", "==", true)
            .where("deleted", "==", false)
            .orderBy("name", "asc")
        );
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.logo,
.cover {
  margin-top: 20px;
  cursor: pointer;
}
.save-btn {
  margin-top: 20px;
}
.add-tag {
  background-color: rgba(0, 0, 0, 0.37);
}
.add-tag-ico {
  font-size: 25px !important;
  cursor: pointer;
  margin-left: 5px;
}
.btn-modal-add-tags {
  justify-content: center;
}
.upload-container .logo {
  width: 10%;
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -8%;
  margin-left: 20px;
}
.logo-up {
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -100px;
  margin-left: 20px;
  cursor: pointer;
}
.upload-container .banner {
  border: 1px solid black;
  border-radius: 10px;
  max-width: 100%;
  max-height: 250px;
  object-fit: cover;
}
.upload {
  display: none;
}
.cover-up {
  border: 1px solid black;
  border-radius: 10px;
}
.logo-up:hover {
  border: 2px dashed #f06f3d;
  cursor: pointer;
}
.cover-up:hover {
  border: 1px dashed #f06f3d;
  cursor: pointer;
}
.add-image {
  font-size: 30px;
  color: rgba(128, 128, 128, 0.733) !important;
  cursor: pointer;
}
.img-tag {
  background-color: rgba(231, 231, 230, 0.808) !important;
  justify-content: center;
  font-weight: bold;
}
.preview-image {
  max-width: 540px;
  min-width: 540px;
  max-height: 540px;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  border: 1px solid #80808080;
  box-shadow: 0 0 0 0;
}
</style>
